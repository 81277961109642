<template>
  <nav :class="{active: navActive}">
    <div class="inner">
      <ul class="gnb">
        <li>
          <router-link to="/works" @click.native="$emit('update:navActive', false)" class="anime1">
            Works
          </router-link>
        </li>
        <li>
          <router-link to="/about" @click.native="$emit('update:navActive', false)" class="anime1">
            About
          </router-link>
        </li>
        <li>
          <router-link
            to="/careers"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
          >
            Careers
          </router-link>
        </li>
        <li>
          <router-link
            to="/request"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
          >
            Request
          </router-link>
        </li>
      </ul>
      <ul class="sns">
        <!--<li>
          <router-link to="/" @click.native="$emit('update:navActive', false)" class="anime1">
            Home
          </router-link>
        </li>-->
        <li>
          <router-link
            to="/magazine"
            @click.native="$emit('update:navActive', false)"
            class="anime1"
          >
            Magazine
          </router-link>
        </li>
        <li>
          <a href="https://www.facebook.com/emotion.co.kr/" target="_blank" class="anime1"
            >Facebook</a
          >
        </li>
      </ul>
      <div class="mark">
        <div class="mark-inner">
          <p class="en">MAKE · GREATNESS</p>
          <p class="ko">위대함을 만들어 갑니다.</p>
        </div>
      </div>
    </div>
    <div class="bg"></div>
  </nav>
</template>
<script>
  export default {
    name: "AppNavigation",
    props: ["navActive"]
  };
</script>
<style lang="scss" scoped>
  nav {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: $globalWidth;
    height: 100%;
    .bg {
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ee2c3c;
    }
    .inner {
      z-index: 3;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      box-sizing: border-box;
      padding: 60px;
    }
    .gnb {
      @include montserrat;
      letter-spacing: 0;
      li {
        font-size: 40px;
        line-height: 65px;
        font-weight: bold;
        color: #fff;
        overflow: hidden;
        &:nth-child(n + 3) {
          margin-top: 5px;
        }
      }
      a {
        display: block;
        transform: translateY(-110%);
      }
    }
    .sns {
      position: absolute;
      top: 116px;
      right: 30px;
      left: 50px;
      display: flex;
      @include montserrat;
      letter-spacing: 0;
      justify-content: flex-end;
      /* li:first-child {
        margin-right: auto;
      }*/
      li {
        position: relative;
        font-size: 13px;
        line-height: 17px;
        color: #fff;
        font-weight: bold;
        overflow: hidden;
      }
      a {
        display: block;
        padding: 10px;
        transform: translateY(-110%);
        /*&:before {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: block;
        width: calc(100% - 20px);
        height: 1px;
        background: #fff;
      }*/
      }
    }
    .mark {
      position: absolute;
      bottom: 41px;
      right: 35px;
      font-size: 10px;
      line-height: 10px;
      .mark-inner {
        opacity: 0;
        &:before {
          content: "";
          display: block;
          width: 70px;
          height: 71px;
          margin: 0 auto 30px;
          background: url(../assets/images/gnb-mark.png) no-repeat center / cover;
        }
      }
      .en {
        @include montserrat;
        letter-spacing: 0;
        color: #fff;
      }
      .ko {
        margin-top: 7px;
        color: #fb8992;
      }
    }
  }
</style>
